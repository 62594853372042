.projects-list {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: auto;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.project-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    min-width: 500px;
    max-width: 1000px;
    background-color: #00000080;
    border-radius: 25px;
    padding: 15px;

    >p {
        text-align: left;
    }
}

.project-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-links {
    scale: 0.5;
}

.project-header {
    display: flex;
    justify-content: space-between;
    height: 5em;
}

.project-icon {
    border-radius: 45px;
    height: 5em;
    width: 5em;
}