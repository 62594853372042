

.app {
  text-align: center;
}

.logo-container {
  position: relative;
  width: 500px;
  height: 500px;
  user-select: none;
  pointer-events: none;
  img {
    min-width: 100%;
    max-width: 100%;
  }
}


.app-logo {
  /* opacity: 1.0; */
  pointer-events: none;
  animation: fade 60s infinite;
}

@keyframes fade {
  0% {
    opacity: 1.0;
  }
  5% {
    opacity: 1.0;
  }
  10% {
    opacity: 0.0
  }
  95% {
    opacity: 0.0
  }
  100% {
    opacity: 1.0;
  }
}

.left-eye {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  height: 82px;
  width: 82px;
  z-index: 100;
  left: 118px;
  top: 223px;
  background: #f7f3f0;
  overflow: hidden;
}

.right-eye {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  height: 70px;
  width: 75px;
  z-index: 100;
  right: 131px;
  top: 225px;
  background: #f7f3f0;
  overflow: hidden;
}

#left-pupil {
  user-select: none;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  height: 70px;
  width: 30px;
  z-index: 200;
  right: 30px;
  top: 10px;
  background: #2f1f0e;
  
}

#right-pupil {
  user-select: none;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  height: 70px;
  width: 30px;
  z-index: 200;
  right: 30px;
  top: 0px;
  background: #2f1f0e;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

body {
  background-color: #282c34;
  animation: body_background 60s infinite;
}

@keyframes body_background {
  0% { background-color: #282c34 }
  50% { background-color: rgb(172, 70, 38); }
  100% { background-color: #282c34;}
}

.app-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1.5em;
  max-width: 80%;

  border-top: 2px solid #d4d4d4d4;

}

.spacer {
  display: flex;
  flex-grow: 1;
}

.app-link {
  /* color: #61dafb; */
  color: #e9e6e4;
  text-decoration: none;
  padding: 10px 15px 10px 15px;
  text-align: center;
  vertical-align: center;
  user-select: none;

  /* border-color: #d4d4d4; */

  &.selected {
    /** More opaque version of the hover color */
    background-color: #d4d4d41c;
  }

  &:hover {
    background-color: #d4d4d40c;
  }
}

.tab-content {
  max-width: 80%;
  font-size: 24px;
}

#about {
  text-align: justify;
  max-width: 60%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'PressStart2P';
  src: local('PressStart2P-Regular'), url('./PressStart2P-Regular.ttf'), format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.app-title-container {
  position: relative;
}

.app-title {
  font-family: 'PressStart2P';
  cursor: crosshair;
  user-select: none;
}

.dynamic-spacer {
  display: flex;
  direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 80px;
}

/** Mail chimp related classes. */
#mc-embedded-subscribe-form {
  size: 16px;
  line-height: normal;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
  gap: 15px;
}

#mce-EMAIL {
  line-height: 2em;
  min-width: 15em;
}

body {
  transition: all 0.2s ease-in-out;
}