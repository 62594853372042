.social-bar {
    display: flex;
    direction: row;
    gap: 20px;
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
}

.social-button-icon {
    height: 60px;
    margin: 20px;
    /* width: 60px; */
    user-select: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        height: 80px;
        margin: 20px 10px 0px 10px;
        margin-bottom: 0px;
    }
}
